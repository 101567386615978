import "./style.scss";

import SectionWorksItem from "../SectionWorkItem";

export default function SectionWorksList({ list }) {

    return (
        <div className="works-list-container">
            {
                list.map(({ image, title, description, type, link }, i) => (
                    <SectionWorksItem
                        key={`works_item_${i}`}
                        image={image}
                        title={title} type={type}
                        description={description}
                        contentDirection={i % 2 !== 0 ? "left" : "right"}
                        link={link}
                    />
                ))
            }
        </div>
    );
}