export const PlayablesList = [
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Rollic%20Games-125893/High%20Heels%21/pla/index.html",
        "image": "playable-ad-1.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/BADSNOWBALL%20HONGKONG%20LIMITED-139711/Beat%20Shooter%3ARhythm%20shooting/pla/index.html?config=1",
        "image": "playable-ad-2.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/BoomBit%20S.A.-5697/Shoe%20Race/pla/index.html",
        "image": "playable-ad-3.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Voodoo-93329/Catch%20%26%20Shoot/pla/index.html",
        "image": "playable-ad-4.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Amanotes%20Pte.%20Ltd.-109327/Beat%20Blader%203D/pla/index.html?config=1",
        "image": "playable-ad-5.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/UNICO%20STUDIO%20LLC-138893/Who%20is%202%3F%20Tricky%20Chats%20and%20Brain%20Puzzles/pla/index.html",
        "image": "playable-ad-6.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Honeybadger%20Technologies%20LLC-111049/Fashion%20Battle/pla/index.html",
        "image": "playable-ad-7.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/LUCKY%20TYCOON%20STUDIO%20CO.%2CLIMITED-127089/Lucky%20Money/pla/index.html",
        "image": "playable-ad-8.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/FUNNII%20PTE.%20LTD.-128237/Erase%20Story/pla/index.html",
        "image": "playable-ad-9.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Digital%20Things%20S.L.%20-%20Codigames-53247/Idle%20Barber%20Shop%20Tycoon/pla/index.html?config=3",
        "image": "playable-ad-10.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Yso+Corp-132581/Stretch+Guy/pla/index.html?config=2",
        "image": "playable-ad-11.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Rollic%20Games-125893/Arrow%20Fest/pla/index.html",
        "image": "playable-ad-12.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/snail%20technology%20limited-144091/Dropping%20Ball%202/pla/index.html",
        "image": "playable-ad-13.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Panteon%20Yazilim%20Oyun%20Egitim%20Tek%20ve%20Hizm.%20San.%20Tic.%20Ltd.%20Sti.-115795/Roll%20Merge%203D/pla/index.html?config=1",
        "image": "playable-ad-14.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/AZUR%20INTERACTIVE%20GAMES%20LIMITED-110829/Do%20Not%20Fall%20.io/pla/index.html",
        "image": "playable-ad-15.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Good%20Job%20Games%20Bilisim%20Yazilim%20ve%20Pazarlama%20AS-109187/Fun%20Race%203D/pla/index.html",
        "image": "playable-ad-16.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Tuyoo-QM%20Studio-173619/Solitaire%20Cashore/pla/index.html",
        "image": "playable-ad-17.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Amanotes%20Pte.%20Ltd.-109327/Game%20of%20Songs/pla/index.html",
        "image": "playable-ad-18.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/WRGames%20publishing-123589/Crash%20Delivery/pla/index.html?config=1",
        "image": "playable-ad-19.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/ABI%20GLOBAL%20LTD-104567/Craft%20Runner/pla/index.html",
        "image": "playable-ad-20.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Homa+Games-117659/Kaiju+Run/pla/index.html",
        "image": "playable-ad-21.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/geisha-tokyo-inc-/rescue-machine/pla/index.html?config=1",
        "image": "playable-ad-22.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/BIGGER%20GAMES%20LTD-124875/Color%20Pop%3A%20Matching%20Puzzle/pla/index.html",
        "image": "playable-ad-23.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Juicy-118969/Hero%20VS%20Criminal/pla/index.html",
        "image": "playable-ad-24.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Tap2Play%20LLC-181350/Fidget%20Toys%20Trading%203D/pla/index.html",
        "image": "playable-ad-25.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Bitmango-71375/Block%21%20Hexa%20Puzzle™/pla/index.html?config=1",
        "image": "playable-ad-26.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/LUCKY%20TYCOON%20STUDIO%20CO.%2CLIMITED-127089/Spin%20for%20Win/pla/index.html",
        "image": "playable-ad-27.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/ABI+GLOBAL+LTD-104567/Galaxy+Attack%3A+Alien+Shooter/pla/index.html",
        "image": "playable-ad-28.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Digital+Things+S.L.+-+Codigames-53247/Law+Empire+Tycoon/pla/index.html",
        "image": "playable-ad-29.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Rollic%20Games-125893/Full%20Metal%203D/pla/index.html",
        "image": "playable-ad-30.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/snail%20technology%20limited-144091/Hyper%20Pusher/pla/index.html",
        "image": "playable-ad-31.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Mistplay-132169/MISTPLAY%3A%20Gift%20Cards%20%26%20Rewards%20For%20Playing%20Games/pla/index.html?config=3",
        "image": "playable-ad-32.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Yso+Corp-132581/Stretch+Guy/pla/index.html?config=1",
        "image": "playable-ad-33.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Eureka%20Studio%20Inc.-117927/Hide%20My%20Test%21/pla/index.html",
        "image": "playable-ad-34.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Rollic+Games-125893/Queen+Bee%21/pla/index.html",
        "image": "playable-ad-35.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Game+Theory-129713/LegendArya/pla/index.html",
        "image": "playable-ad-36.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Voodoo-93329/Balls%20Fall%21%203D/pla/index.html",
        "image": "playable-ad-37.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/AZUR%20INTERACTIVE%20GAMES%20LIMITED-110829/Impostor%20Academy%20Match%20%26%20Merge/pla/index.html",
        "image": "playable-ad-38.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/JustDice%20GmbH-97417/Cashyy/pla/index.html?config=1",
        "image": "playable-ad-39.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Good%20Job%20Games%20Bilisim%20Yazilim%20ve%20Pazarlama%20AS-109187/Color%20Fill%203D/pla/index.html?config=1",
        "image": "playable-ad-40.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/AppLike-97417/Cash%27em%20All/pla/index.html",
        "image": "playable-ad-41.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/AppLike-97417/App%20Flame%3A%20Play%20Games%20%26%20Get%20Rewards/pla/index.html",
        "image": "playable-ad-42.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Enjoy%20mobile%202020-171689/Happy%20Rolling-Fun%20Dice%20game/pla/index.html",
        "image": "playable-ad-43.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/Sunday%20GmbH-162039/Plug%20Head/pla/index.html",
        "image": "playable-ad-44.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/TAPNATION-129461/Crowd%20Battle%203D/pla/index.html",
        "image": "playable-ad-45.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/IEC%20Global%20Pty%20Ltd-117031/Water%20Sort%20Puzzle/pla/index.html",
        "image": "playable-ad-46.jpg"
    },
    {
        "link": "https://pwcreatives.s3.amazonaws.com/demo/TabTale-970/Pop%20It%20Rush/pla/index.html",
        "image": "playable-ad-47.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Good+Job+Games+Bilisim+Yazilim+ve+Pazarlama+AS-109187/Zen+Match+v2/pla/index.html",
        "image": "playable-ad-48.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Rollic+Games-125893/Blob+Runner+3D/pla/index.html",
        "image": "playable-ad-49.jpg"
    },
    {
        "link": "https://pwcreatives.s3.eu-west-1.amazonaws.com/demo/Rollic+Games-125893/Hair+Challenge/pla/index.html",
        "image": "playable-ad-50.jpg"
    }
];