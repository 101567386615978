import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";
import LazyLoadingImage from "../../shared/LazyLoadImage";


export default function GameEnviromentsPage() {
    return (
        <div className='works-item-page'>
            <div className="wrapper">
                <h1>Game environments</h1>
                <BreakLine/>

                <p className="works-item-description">Some of examples of different environments for mobile hypercasual games. Made with Blender and Unity.</p>

                <div className="game-env-group">
                    {
                        Array(7).fill(null).map((_, i) => (
                            <LazyLoadingImage
                                key={i}
                                image={{src: require(`../../images/game-enviroment-page/game-env-${i + 1}.jpg`)}}
                            />
                        ))
                    }
                </div>
                
            </div>
        </div>
    )
}