import "./style.scss";
import { TechStackListArray } from "../../configs";

export default function TechStackList({ listArray = TechStackListArray }) {
    return (
        <ul className="techstack-list-container">
            {
                listArray.map(({ name, icon }, i) => (
                    <li className="techstack-list-item" key={i}>
                        <span className={`techstack-list-item-icon techstack-list-item-icon-${icon}`} />
                        <span className="techstack-list-item-title">{name}</span>
                    </li>
                ))
            }
        </ul>
    );
}