import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyLoadingImage = ({ className, image, afterLoad = () => {} }) => (
    <LazyLoadImage
        alt={image.alt}
        height={image.height}
        effect="blur"
        src={image.src} // use normal <img> attributes as props
        width={image.width}
        className={className}
        afterLoad={afterLoad}
    />
);

export default LazyLoadingImage;