import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";
import LazyLoadingImage from "../../shared/LazyLoadImage";


export default function SheBeautyPage() {
    return (
        <div className='works-item-page'>
            <div className="wrapper">
                <h1>SHE - beauty shop</h1>
                <BreakLine/>

                <p className="works-item-description">Brand identity for the local shop of skin care cosmetics.</p>

                <div className="flop-group">
                    {
                        Array(1).fill(null).map((_, i) => (
                            <LazyLoadingImage 
                                className="wide-image"
                                key={i}
                                image={{src: require(`../../images/she-beauty-page/she-beauty-img-${i + 1}.jpg`)}}
                            />
                        ))
                    }
                </div>
                
            </div>
        </div>
    )
}