import { useEffect, useRef } from "react"


export default function GradientCanvasBackground({styles, id = "gradient-canvas-bg"}) {

    const width = 600;
    const height = 600;
    const canvasId = id;

    const resizeCanvas = () => {
        let scale = window.visualViewport.width / 600;

        // if (window.visualViewport.width < 640) {
        //     scale = 2;
        // }
        const canvas = document.getElementById(canvasId);
        if (canvas) {
            canvas.style.transform = `translate3d(0, 0, 0) scale(${scale}) translateX(-50%)`;
        }
    }

    window.addEventListener('resize', resizeCanvas);
    

    function drawCircle(ct, x, y, iR, oR, color, secondColor) {
        const gradient = ct.createRadialGradient(x,y,iR, x,y,oR);
        gradient.addColorStop(0, color);
        gradient.addColorStop(1, secondColor);
        return gradient;
    }

    const balls = [
        {x: width / 2 - 100, y: height / 2, dx: -5, dy: 2, color: 'hsla(25, 100%, 74%, 1)', secondColor: 'hsla(25, 100%, 74%, 0)'},
        {x: width / 2, y: height / 2 - 100, dx: -3, dy: -2, color: "hsla(238, 100%, 80%, 1)", secondColor: 'hsla(238, 100%, 80%, 0)'},
        
        {x: width / 2, y: height / 2, dx: 4, dy: -4,color: "hsla(306, 100%, 70%, 1)", secondColor: 'hsla(306, 100%, 70%, 0)'},
        {x: width / 2 + 100, y: height / 2, dx: 5, dy: 2, color: "hsla(101, 94%, 85%, 1)", secondColor: 'hsla(101, 94%, 85%, 0)'}
    ];

    // const balls = [
        
    //     {x: width / 2, y: height / 2 - 100, dx: -1, dy: -1, color: "hsla(16, 100%, 70%, 1)", secondColor: 'hsla(16, 100%, 70%, 0)'},
    //     {x: width / 2 - 100, y: height / 2, dx: -2, dy: 3, color: 'hsla(173, 100%, 50%,1)', secondColor: 'hsla(173, 100%, 50%,0)'},
        
    //     {x: width / 2, y: height / 2, dx: 4, dy: -2,color: "hsla(303, 100%, 70%, 1)", secondColor: 'hsla(303, 100%, 70%, 0)'},
    //     //{x: width / 2 + 100, y: height / 2, dx: 5, dy: 5, color: "hsla(289, 100%, 50%, 1)", secondColor: 'hsla(101, 94%, 85%, 0)'}
    // ];

    const ballRadius = 200;

    function lerp(start, end, t) {
        return start * (1 - t) + end * t;
    }


    useEffect(() => {

        resizeCanvas()
        let canvas = document.getElementById(canvasId);
        
        
        let updateCanvas = () => {};

        if (canvas) {
            canvas.width = width;
            canvas.height = height;


            const ctx = canvas.getContext("2d");
            ctx.globalCompositeOperation = "saturation";

            updateCanvas = (dt) => {

                if (canvas && ctx) {
                    const sinusodalMovement = Math.sin((dt / 1000) * Math.PI) * 8;
                    ctx.clearRect(0, 0, width, height);
                    balls.forEach(({x, y, dx, dy, color, secondColor}, i) => {
                        const R = i % 2 === 0 ? ballRadius - sinusodalMovement : ballRadius + sinusodalMovement 
                        const gradient = drawCircle(ctx, x, y, 1, R, color, secondColor);
                        
                        ctx.fillStyle = gradient;
                        ctx.fillRect(0, 0, width, height);
    
                        if(x + dx > width - ballRadius || x + dx < ballRadius) {
                            balls[i].dx = -dx;
                        }
                        if(y + dy > (height - 100) - ballRadius || y + dy < ballRadius) {
                            balls[i].dy = -dy;
                        }

                        balls[i].x += dx;
                        balls[i].y += dy;
                        balls[i].x = lerp(x, balls[i].x, .2);
                        balls[i].y = lerp(y, balls[i].y, .2);
                    });
                }
                requestAnimationFrame(updateCanvas);
            }
            
            updateCanvas(0);
            
        }

        return () => {
            cancelAnimationFrame(updateCanvas);
            window.removeEventListener('resize', resizeCanvas);
            canvas = null;
        };
    }, []);

    return (
        <canvas
            id={canvasId}
            style={{
                ...styles
            }}
        />
    )
}