import { useState, Fragment } from "react";
import PlayableContainer from "../PlayableContainer";
import { PlayablesList as PlayablesArray } from "../../configs";

import './style.scss';

const imagePerLoad = 10;

export default function PlayablesList({ playablesList = PlayablesArray }) {
    const [next, setNext] = useState(imagePerLoad);
    const [loadButtonEnable, setLoadButtonEnable] = useState(true);

    const handleMoreImage = () => {
        setNext(next + imagePerLoad);

        if (next >= playablesList.length) {
            setLoadButtonEnable(false);
        }
    };

    return (
        <Fragment>
            <div className="playables-list-container">
                {
                    [...playablesList].splice(0, next).map(({image, link}, i) => {
                        return (
                            <PlayableContainer
                                key={i} image={image} link={link}
                            />
                        )
                    })
                }
            </div>
            
            {loadButtonEnable && (
                <div onClick={handleMoreImage} className="playables-load-button">
                    Load More
                </div>
            )}
        </Fragment>
        
    );
}