export const NavBarLinks = [
    // {
    //     name: 'Bilonohova Zlata Desing',
    //     to: '/'
    // },
    {
        name: 'Works',
        to: 'works'
    },
    {
        name: 'About',
        to: 'about'
    },
    {
        name: 'Process',
        to: 'process'
    },
    {
        name: 'Contacts',
        to: 'contacts'
    }
];