import { Route, Routes, Navigate  } from "react-router-dom";


import HomePage from "../pages/Home";
import CuteCharactersPage from "../pages/CuteCharacters";
import NightCastlePage from "../pages/NightCastle";
import PlayablesPage from "../pages/Playables";
import GameEnviromentsPage from "../pages/GameEnviroment";
import FlopPage from "../pages/Flop";
import PortinvestPage from "../pages/PortInvest";
import PromotradePage from "../pages/Promotrade";
import SheBeautyPage from "../pages/SheBeauty";
import EspressoTVPage from "../pages/Espresso";


const routesArray = [
    {
        name: 'Home',
        path: '/',
        element: (props) => <HomePage props={props} />
    },
    {
        title: 'Cute Characters',
        type: '3D',
        description: "Set of cute 3D character models made with Blender.",
        path: '/cute-characters',
        element: (props) => <CuteCharactersPage/>
    },
    {
        name: 'Night Castle',
        path: '/night-castle',
        element: (props) => <NightCastlePage/>
    },
    {
        name: 'Game Enviroments',
        path: '/game-enviroments',
        element: (props) => <GameEnviromentsPage/>
    },
    {
        name: 'Playables Ads',
        path: '/playables-ads',
        element: (props) => <PlayablesPage/>
    },
    {
        name: 'Flop',
        path: '/flop',
        element: (props) => <FlopPage/>
    },
    {
        name: 'Portinvest',
        path: '/portinvest',
        element: (props) => <PortinvestPage/>
    },
    {
        name: 'Promotrade',
        path: '/promotrade',
        element: (props) => <PromotradePage/>
    },
    {
        name: 'She Beauty',
        path: '/she-beauty-shop',
        element: (props) => <SheBeautyPage/>
    },
    {
        name: 'Espresso TV',
        path: '/espresso-tv',
        element: (props) => <EspressoTVPage/>
    },
]



export default function AppRoutes({isFirstTouch, setFirstTouch}) {
    return (
        <Routes >
            {
                routesArray.map(({path, element: Component}, i) => {
                    return <Route 
                    key={path} path={path} element={Component({isFirstTouch, setFirstTouch})}></Route>
                })
                
            }
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}