import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-scroll';

import './style.scss';

export default function MobileMenu({ navBarLinks, setMobileOpen, isMobileOpen }) {

    // const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setMobileOpen(!isMobileOpen)
    }

    const closeSideBar = () => {
        setMobileOpen(false)
    }

    return (
        <Menu disableAutoFocus right isOpen={isMobileOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}>
            {
                navBarLinks.map(({name, to}, i) => {
                    return <Link key={i} activeClass="active" onClick={closeSideBar} spy={true} smooth={true} to={to} offset={-60}>{name}</Link>
                })
            }
        </Menu>
    );
};