import { Link } from 'react-scroll';
import TypeAnimation from 'react-type-animation';

import './style.scss';
import useCopy from '../../../../hooks/useCopy';
import ReactTyped from '../../../../shared/Typed';
import { useState, useEffect } from 'react';

export default function IntroSection({isFirstTouch = sessionStorage.getItem('isFirstTouch')}) {
    // const 
    const [copied, copy, setCopied] = useCopy('belonogovazv@gmail.com');
    const isTouched = true//eval(sessionStorage.getItem('isFirstTouch'));

    const copyText = () => {
        copy();
     
        setTimeout(() => {
          setCopied(false);
        }, 3000);
    };

    useEffect(() => {
        if (!isTouched) {
            document.body.style.overflow = 'hidden';
        }
    }, []);

    const introString = "Hey! I am Zlata Bilonohova, 3D, graphic and UI designer. I like hypercasual games and simple trendy designs.";


    return (
        <section id="intro" className="intro-section not-animated">
            <div className="wrapper">
                <p className="intro-description">
                    <span style={{maxWidth: '948px', display: 'block'}}>
                        {!isTouched ? <ReactTyped 
                            strings={[introString]}
                            typeSpeed={10}
                            backSpeed={50}
                            onComplete={() => {
                                document.getElementById("intro").classList.remove("not-animated");
                                document.getElementById("intro").className += ' animated'
                            }}
                        /> : introString} 
                    </span>

                    <div className="intro-email-button" onClick={copyText}>
                        {copied ? "Email copied" : "Get in touch"}
                    </div>
                </p>
                
                <div className="intro-scroll-to">
                    <span className="scroll-to-text"><Link to="works" smooth={true} offset={-60}>Discover</Link></span>
                    <span className="scroll-to-arrow"></span>
                </div>
            </div>
            
        </section>
    )
}