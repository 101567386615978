import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";
import LazyLoadingImage from "../../shared/LazyLoadImage";


export default function PortinvestPage() {
    return (
        <div className='works-item-page'>
            <div className="wrapper">
                <h1>Portinvest </h1>
                <BreakLine/>

                <p className="works-item-description">Corporate website for a large logistics company.</p>

                <div className="flop-group">
                    {
                        Array(6).fill(null).map((_, i) => (
                            <LazyLoadingImage 
                                className="wide-image"
                                key={i}
                                image={{src: require(`../../images/portinvest-page/portinvest-${i + 1}.jpg`)}}
                            />
                        ))
                    }
                </div>
                
            </div>
        </div>
    )
}