import { NavLink, useLocation } from "react-router-dom";
import { Link } from 'react-scroll';


import './style.scss';
import { NavBarLinks } from '../../configs';
import { useState, useEffect, useRef, useCallback } from "react";
import MobileMenu from "../BurgerMenu";


const StickyHeader = (defaultSticky = false) => {
    
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const tableRef = useRef(null);
  const toggleSticky = useCallback(
    ({ top }) => {
      if (top * -1 > window.innerHeight - 100) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }
    },
    [isSticky]
  );
  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(document.getElementById("root").getBoundingClientRect());
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);
  return { tableRef, isSticky };
};
// export default StickyHeader;


export default function NavBar() {
    const { isSticky } = StickyHeader();
    const [isMobileOpen, setMobileOpen] = useState(false)
    const [activePage, setPage] = useState('/');
    const location = useLocation();

    let onClickCb = () => {
        if (isMobileOpen) {
            setMobileOpen(false);
        }
    };

    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
        setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 768;

    useEffect(() => {

        if (location.pathname !== activePage) {
            setPage(location.pathname);
            window.scrollTo(0, -100);
        }
       
    }, [location]);

    const filteredNavs = NavBarLinks.filter(({name}) => {
        if (activePage !== '/') {
            if (name === 'Works' || name === 'Contacts') {
                return true;
            }
            return false;
        }

        return true;
    })

    return (
        <div className={`nav-bar ${isSticky ? 'fixed' : ''}`} >
            <div className="nav-bar-wrapper">
                <div className="nav-bar-logo">
                    {
                        activePage === '/' ? 
                        <Link smooth={true} onClick={onClickCb} to="intro">
                            <span style={{
                                width: '9px',
                                height: '9px',
                                background: '#989CFF',
                                display: 'block',
                                borderRadius: '5px'
                            }}/> <span>Bilonohova Zlata Desing</span> 
                        </Link>
                        :
                        <NavLink to="/" onClick={onClickCb}> <span style={{
                            width: '9px',
                            height: '9px',
                            background: '#989CFF',
                            display: 'block',
                            borderRadius: '5px'
                        }}/> <span>Bilonohova Zlata Desing</span> </NavLink>
                        
                    }
                    </div>

                {
                    isMobile
                        ? <MobileMenu navBarLinks={filteredNavs} isMobileOpen={isMobileOpen} setMobileOpen={setMobileOpen} />
                        : <ul>
                            {
                                filteredNavs.map(({name, to}, i) => {
                                return (
                                    <li key={i}>
                                        <Link key={i} activeClass="active" spy={true} smooth={true} to={to} offset={-60}>{name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                }    
                
            </div>
        </div>
    );
}