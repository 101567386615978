export const ExperienceListArray = [
    {
        years: '2020-2022',
        company: 'IronSource',
        description: 'Playables creation. Game levels design, animation 2D/3D, workshops, UI'
    },
    {
        years: '2019-2020',
        company: 'Frontmen',
        description: 'Graphic design, Branding, UI/UX'
    },
    {
        years: '2018-2019',
        company: 'Freelance',
        description: 'Brand indentity, web and mobile solutions'
    }
];