import './style.scss';
import ClientsList from "../../../../components/ClientsList";
import ExperienceList from "../../../../components/ExperienceList";
import TechStackList from '../../../../components/TechStackList';

import GradientCanvasBackground from '../../../../components/GradientCanvasBackground';

export default function AboutSection() {
    return (
        <section id="about" className="about-section">

            <div className="canvas-about-container">
                <GradientCanvasBackground styles={{
                    position: "absolute",
                     top: 0,
                    left: '50%',
                    right: 0,
                    zIndex: -1,
                    transformOrigin: '0 center',
                    transform: 'translate3d(0, 0, 0)'
                }} />
            </div>

            <div className="wrapper">
                <h2>About</h2>

                <p className="about-description">
                    Hey, I am Zlata Bilonohova, designer with 4+ years of experience. I have expertise in graphic and UI/UX design. The main core of my professional activities last few years was the games industry. I had created a lot of scenes, characters, and animations for different genres of mobile game advertising, specializing in hypercasual games. I`ve got 3D modeling and animating skills. I am always open to new knowledge and ready to share my experience.
                </p>

                <h3>Clients:</h3>
                <div className="clients-list-wrapper">
                    <ClientsList />
                </div>

                <h3>Tech Stack:</h3>
                <div className="techstack-list-wrapper">
                    <TechStackList />
                </div>

                <h3>Experience</h3>
                <div className="experience-list-wrapper">
                    <ExperienceList />
                </div>

            </div>
        </section>
    )
}