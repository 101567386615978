
import './style.scss';
import BreakLine from '../../../../shared/Line';

import chars from '../../../../images/chars.jpg'
import env from '../../../../images/env.jpg'
import playables from '../../../../images/playables.jpg'
import flop from '../../../../images/flop.jpg'
import promotrade from '../../../../images/promotrade.jpg'
import she from '../../../../images/she.jpg'
import espresso from '../../../../images/espresso.jpg'
import portinvest from '../../../../images/portinvest.jpg'
import castle from '../../../../images/castle.jpg'
import SectionWorksList from '../../../../components/SectionWorkList';


const worksSectionList = [
    {
        title: 'Cute Characters',
        type: '3D',
        description: "Set of cute 3D character models made with Blender.",
        image: chars,
        link: '/cute-characters'
    },
    {
        title: 'Night Castle',
        type: '3D',
        description: "Lights and shadows of the night mystical castle. Made with Blender.",
        image: castle,
        link: '/night-castle'
    },
    {
        title: 'Game environments',
        type: '3D',
        description: "Some of examples of different environments for mobile hypercasual games. Made with Blender and Unity.",
        image: env,
        link: '/game-enviroments'
    },
    {
        title: 'Playable Ads',
        type: 'Games',
        description: "The main direction of my work for the last few years. You can find here a lot of mini-games. All of the animations, assets, and designs were made by me.",
        image: playables,
        link: '/playables-ads'
    },
    {
        title: 'Flop',
        type: 'Branding',
        description: "The brand identity of a local small coffee shop.",
        image: flop,
        link: '/flop'
    },
    {
        title: 'Portinvest',
        type: 'UI/UX',
        description: "Corporate website for a large logistics company.",
        image: portinvest,
        link: '/portinvest'
    },
    {
        title: 'Promotrade',
        type: 'UI/UX',
        description: "Redesign of a corporate website for Promotrade Group, importer of the supply of spare parts for road construction.",
        image: promotrade,
        link: '/promotrade'
    },
    {
        title: 'SHE - beauty shop',
        type: 'Branding',
        description: "Brand identity for the local shop of skin care cosmetics.",
        image: she,
        link: '/she-beauty-shop'
    },
    {
        title: 'Espresso TV',
        type: 'UI/UX',
        description: "UX and design for multi-page news portal.",
        image: espresso,
        link: '/espresso-tv'
    },
]

export default function WorksSection() {
    return (
        <section id="works" className="works-section">
            <div className="wrapper">
            <h2>Works</h2>
            <BreakLine style={{
                marginBottom: '100px',
                marginTop: '40px'
            }}/>
            <SectionWorksList list={worksSectionList} />
            </div>
        </section>
    )
}