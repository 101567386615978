import LazyLoadingImage from "../../shared/LazyLoadImage";

import './style.scss';

export default function PlayableContainer({image, link}) {
    const onClickHandler = () => window.open(link, '_blank');

    return (
        <div onClick={onClickHandler} className="playable-container">
            <LazyLoadingImage image={{
                src: require(`../../images/playables-ads-page/${image}`)
            }} />

            <div className="playable-hover-container">
                <span>Open to Play</span>
                <LazyLoadingImage image={{
                    src: require('../../images/play.png')
                }} />
            </div>
        </div>
    )
}
