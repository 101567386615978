import "./style.scss";
import '../sharedStyles.scss';

import { LazyLoadComponent } from 'react-lazy-load-image-component';

import IntroSection from "./sections/Intro";
import WorksSection from "./sections/Works/Works";
import AboutSection from "./sections/About";
import ProcessSection from "./sections/Process";
import GradientCanvasBackground from "../../components/GradientCanvasBackground";
import { useEffect } from "react";

export default function HomePage({props}) {

    useEffect(() => {
        props.setFirstTouch();
    }, []);

    return (
        <div className='home-page'>
            <div className="canvas-container">
            <GradientCanvasBackground styles={{
                position: "absolute",
                //top: 0,
                left: '50%',
                right: 0,
                zIndex: -1,
                //marginTop:'-50%',
                transformOrigin: '0 center',
                transform: 'translate3d(0, 0, 0)'
            }} id="intro-bg" />
            </div>

                <IntroSection isFirstTouch={props.isFirstTouch}/>

                <LazyLoadComponent>
                    <WorksSection/>
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <AboutSection/>
                </LazyLoadComponent>
                <LazyLoadComponent>
                    <ProcessSection/>
                </LazyLoadComponent>
        </div>
    )
}