import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";

import CharactersAll1 from "../../images/cute-characters-page/cute-characters-1.jpg";

import Avocado1 from "../../images/cute-characters-page/cute-characters-2.jpg";
import Avocado2 from "../../images/cute-characters-page/cute-characters-3.jpg";
import Avocado3 from "../../images/cute-characters-page/cute-characters-4.jpg";

import Mushroom from "../../images/cute-characters-page/cute-characters-5.jpg";

import Mushroom1 from "../../images/cute-characters-page/cute-characters-6.jpg";
import Mushroom2 from "../../images/cute-characters-page/cute-characters-7.jpg";
import Mushroom3 from "../../images/cute-characters-page/cute-characters-8.jpg";

import marshmallow from "../../images/cute-characters-page/cute-characters-9.jpg";
import marshmallow2 from "../../images/cute-characters-page/cute-characters-10.jpg";
import marshmallow3 from "../../images/cute-characters-page/cute-characters-11.jpg";


import CharactersAll2 from "../../images/cute-characters-page/cute-characters-12.jpg";
import LazyLoadingImage from "../../shared/LazyLoadImage";

export default function CuteCharactersPage() {
    return (
        <div className='works-item-page cute-characters'>
            <div className="wrapper">
                <h1>Cute Characters</h1>
                <BreakLine/>

                <p className="works-item-description">Set of cute 3D character models made with Blender.</p>

                <LazyLoadingImage className="wide-image" image={{
                    src: CharactersAll1
                }}/>

                <div className="avocado-group padding-top-m">
                    <LazyLoadingImage className="group-avocado-1" image={{src: Avocado1}}/>
                    <LazyLoadingImage className="group-avocado-2" image={{src: Avocado2}}/>
                    <LazyLoadingImage className="group-avocado-3" image={{src: Avocado3}}/>
                </div>


                <LazyLoadingImage className="wide-image padding-top-m" image={{src: Mushroom}}/>

                <div className="mushroom-group padding-top-m">
                    <LazyLoadingImage image={{src: Mushroom1}}/> 
                    <LazyLoadingImage image={{src: Mushroom2}}/> 
                    <LazyLoadingImage image={{src: Mushroom3}}/> 
                </div>

                <div className="marshmallow-group padding-top-m">
                    <LazyLoadingImage image={{src: marshmallow}}/>
                    <div>
                    
                    <LazyLoadingImage image={{src: marshmallow2}}/> 
                    <LazyLoadingImage image={{src: marshmallow3}}/> 
                    </div>
                   
                </div>

                <img className="wide-image padding-top-m" src={CharactersAll2} />
            </div>
        </div>
    )
}