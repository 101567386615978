
import './style.scss';

const processTable = [
    {
        title: 'Research',
        description: "Explore the competitors, experience, and data, try if possible to use similar products, and determine advantages and weaknesses.",
        num: '01'
    },
    {
        title: 'Sketching',
        description: "Making some sketches is the necessary step. It could be on paper or digital, but it is important to understand the idea before design.",
        num: '02'
    },
    {
        title: 'Design',
        description: "After approving the main idea and sketches I start to design and show it to a client. ",
        num: '03'
    },
    {
        title: 'Testing',
        description: "After the design was approved, it is important to test it in different ways: several versions, user engagement, and hooked.",
        num: '04'
    }
]

export default function ProcessSection() {
    return (
        <section id="process" className="process-section">
            <div className="wrapper">
                <h2>Process</h2>
                <p className="process-description">The right design process is the main core of successful results. My approach is based on scrupulous research by analyzing competitors, trends, and previous experience. I always put huge attention on the testing and data. I think it is a real chance to measure product success.</p>
            </div>

            <div className="process-table-bg">
                <div className="wrapper">
                    <ul>
                    {
                        processTable.map(({title, description, num}, i) => (
                            <li key={i}>
                                <span className="process-table-title">{title}</span>
                                <span className="process-table-description">{description}</span>
                                <span className="process-table-num">{num}</span>
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </div>

        </section>
    )
}