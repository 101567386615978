
import './style.scss';
import BreakLine from '../../shared/Line';
import useCopy from '../../hooks/useCopy';

export default function Footer() {

    const [copied, copy, setCopied] = useCopy('belonogovazv@gmail.com');

    const copyText = () => {
        copy();
     
        setTimeout(() => {
          setCopied(false);
        }, 3000);
    };

    return (
        <div id="contacts">
            <div className="contacts-wrapper">
                <h2>Contacts</h2>
                <BreakLine 
                    className="contacts-separator"
                />

                <ul>
                    <li>
                        {copied ? "Copied to clipboard" : <a onClick={copyText}>Email</a>}
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/zlata-belonogova-9b5a09145" rel="noreferrer" target="_blank">LinkedIn</a>
                    </li>
                    <li>
                        ©
                    </li>
                </ul>
            </div>
        </div>
    );
}