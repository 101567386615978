import "./style.scss";

export default function ClientsList() {
    return (
        <div className="clients-list-container">
            {
                Array(12).fill(null)
                    .map((_, i) => <div key={i}
                        className={`clients-list-item clients-list-item-${i + 1}`} />)
            }
        </div>
    );
}