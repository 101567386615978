import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";

import Castle1 from "../../images/night-castle-page/castle1.jpg";
import Castle2 from "../../images/night-castle-page/castle2.jpg";
import Castle3 from "../../images/night-castle-page/castle3.jpg";
import Castle4 from "../../images/night-castle-page/castle4.jpg";
import Castle5 from "../../images/night-castle-page/castle5.jpg";
import LazyLoadingImage from "../../shared/LazyLoadImage";

export default function NightCastlePage() {
    return (
        <div className='works-item-page'>
            <div className="wrapper">
                <h1>Night Castle</h1>
                <BreakLine/>

                <p className="works-item-description">Lights and shadows of the night mystical castle. Made with Blender.</p>

                <div className="night-castle-group">
                    <LazyLoadingImage className="wide-image" image={{src: Castle1}}/>
                    <LazyLoadingImage image={{src: Castle2}}/>
                    <LazyLoadingImage image={{src: Castle3}}/>
                    <LazyLoadingImage image={{src: Castle4}}/>
                    <LazyLoadingImage image={{src: Castle5}}/>
                </div>
                
            </div>
        </div>
    )
}