import './App.css';

import { HashRouter } from 'react-router-dom';
import NavBar from './components/NavBar';
import AppRoutes from "./routes";
import Footer from './components/Footer';
import { useState, useEffect } from 'react';

function App() {
  const [isFirstTouch, setFirstTouch] = useState(true);

  const setTouch = () => {
    setFirstTouch(false);
    sessionStorage.setItem('isFirstTouch', false);
  };

  const getTouch = () => {
    if (sessionStorage.getItem('isFirstTouch')) {
      setFirstTouch(true);
    }
  }

  useEffect(() => {
    getTouch();
  }, []);

  return (
    <HashRouter>
        <NavBar/>
        <AppRoutes isFirstTouch={isFirstTouch} setFirstTouch={setTouch} />
        <Footer/>
    </HashRouter>
  );
}

export default App;
