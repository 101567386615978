import {Fragment} from "react";

import "./style.scss";
import Arrow from '../../images/arrow.png'

import { ExperienceListArray } from "../../configs";
import BreakLine from "../../shared/Line";

export default function ExperienceList({ experienceList = ExperienceListArray }) {
    return (
        <ul className="experience-list-container">
            <BreakLine className="experience-list-line"/>
            {
                experienceList.map(({years, company, description}, i) => (
                    <Fragment key={`k${i}`}>
                        <li >
                            <img className="experience-list-item-arrow" src={Arrow}></img>
                            <span className="experience-list-item-years">{years}</span>
                            <span className="experience-list-item-company">{company}</span>
                            <span className="experience-list-item-description">{description}</span>
                        </li>
                        <BreakLine className="experience-list-line" />
                    </Fragment>
                ))
            }
        </ul>
    );
}