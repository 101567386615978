import "./style.scss";
import "../sharedStyles.scss";
import BreakLine from "../../shared/Line";

import PlayImage from "../../images/play.png";
import LazyLoadingImage from "../../shared/LazyLoadImage";
import PlayablesList from "../../components/PlayablesList";


export default function PlayablesPage() {
    return (
        <div className='works-item-page'>
            <div className="wrapper">
                <h1>Playable Ads</h1>
                <BreakLine/>

                <p className="works-item-description">You can find here a lot of mini-games. All of the animations, assets, and designs were made by me.  </p>

                <div className="playables-ads-group">
                    <PlayablesList />
                </div>
                
            </div>
        </div>
    )
}