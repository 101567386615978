import { useNavigate, Link } from "react-router-dom";
import { useRef, useState } from "react";

import './style.scss';

import LazyLoadingImage from "../../shared/LazyLoadImage";


export default function SectionWorksItem({ image, title, description, type, link, contentDirection = "left" }) {
    const imageRef = useRef();
    const navigate = useNavigate();
    const [imageClasses, setImageClasses] = useState("work-item-image loading");

    const onHover = () => {
        if (imageRef && imageRef.current.className !== imageClasses + " hovered") {
            imageRef.current.className = imageClasses + " hovered";
        }
    };
    
    const onHoverLeave = () => {
        if (imageRef && imageRef.current.className !== imageClasses) {
            imageRef.current.className = imageClasses;
        }
    };

    const onClick = () => {
        if (link) {
            navigate(link, {replace: false});
        }
    };

    return (
        <div className={`work-item-container content-float-${contentDirection}`}>
            <div ref={imageRef} className="work-item-image loading" onClick={() => onClick()}>
                <div className="item-image-effects-container">
                    <LazyLoadingImage image={{
                        src: image
                    }}
                    afterLoad ={() => {
                        setImageClasses("work-item-image")
                        imageRef.current.className = "work-item-image";
                    }}
                    />
                    <div className="work-item-image-hovered">
                        <span>{link ? "VIEW" : "INACTIVE"}</span>
                    </div>
                </div>
            </div>

            <div className="work-item-info">
                <span 
                    className="work-item-title"
                    onMouseEnter={onHover.bind(this)}
                    onMouseLeave={onHoverLeave.bind(this)}
                >
                   {link ? <Link to={link}>{title}</Link> : title}
                </span>
                <span className="work-item-type">{type}</span>
                <span className="work-item-description">{description}</span>
            </div>
        </div>
    )
}